/**
 * Blog Page
 */
@media screen and (max-width: 1200px){
    .btn-continue {
        max-width: 100% !important;
    }
}

@media screen and (max-width: 992px){
    .btn-continue {
        padding: 10px !important;
    }

    .blog-flex-gaps.flex-grid3 {
        grid-template-columns: 1fr 1fr !important;
    }
}

@media screen and (max-width: 768px){
}

@media screen and (max-width: 600px){

}

@media screen and (max-width: 480px){
    .blog-flex-gaps.flex-grid3 {
        grid-template-columns: 1fr !important;
    }

    .blog-grid-p1,
    .title-height,
    .blog-grid-p2 {
        min-height: auto !important;
    }

    .blog-grid-p1 {
        padding-bottom: 20px;
    }
}

/**
 * main css
 */
.blog-cover-bg {
    position: relative;
    padding-bottom: 59px;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 360px;

    background-image: url("../img/landing/blog-cover-bg.png");
    background-color: #f3f5f7;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.select-topic {
    position: absolute;
    top: 80%;
    margin-right: 10px;
    bottom: 60px;
}

select.country-option {
    padding: 9px 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    min-width: 250px;
    width: 100%;
    outline: none !important;
    color: #333;
}

.select-contact {
    width: calc(50% - 10px);
}

.give-us {
    margin: 0 auto;
    line-height: 2;
    width: 75%;
    align-items: center;
}
.blog-grid-mt {
    margin-top: 40px;
}

.blog-header-body {
    padding-top: 68px;
}

.blog-flex-gaps {
    grid-column-gap: 40px;
}

.blog-img-size {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 286px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.blog-img-size:hover {
    transform: scale(1.05);
    box-shadow: 0 0 16px 2px #add;
    z-index: 1000;
    opacity: 0.6;
}

.blog-grid-p1 {
    padding-top: 23px;
    min-height: 60px;
}

.title-height {
    min-height: 75px;
}

.txt-break {
    word-break: break-all;
}

.txt-word {
    word-break: break-word;
}

.blog-home:hover {
    color: var(--col-title-blue);
}
.blog-grid-p2 {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.7;
    min-height: 155px;
}

.btn-continue {
    display: flex;
    margin-top: 19px;
    padding: 15px 40px;
    border: 1px solid var(--col-title-blue);
    border-radius: 4px;
    max-width: 70%;
    color: var(--col-title-blue);
    cursor: pointer;
}

.btn-continue:hover {
    background-color: var(--col-title-blue);
    color: #ffffff;
}
