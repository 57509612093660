@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 * responsive landing page
 */

@media screen and (max-width: 1440px) {
    .flex-grid3.shape {
        grid-template-columns: 1fr 1fr !important;
        grid-auto-rows: 1fr;
        grid-column-gap: 25px;
    }

    .mobile-shape {
        margin-top: 70px;
    }

    .shape-payment1,
    .shape-payment2,
    .shape-payment3 {
        margin-bottom: 100px !important;
    }
}

@media screen and (max-width: 1200px) {
    .flex-grid4, .flex-grid3 {
        grid-template-columns: 1fr 1fr 1fr !important;
        grid-auto-rows: auto !important;
    }

    .flex-grid4.gender {
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        grid-auto-rows: auto !important;
    }
    .flex-grid4.landing {
        grid-template-columns: 1fr 1fr !important;
    }

    .btn-card {
        left: 30% !important;
    }
}

@media screen and (max-width: 992px) {
    .flex-grid3.shape {
        grid-template-columns: 1fr !important;
        grid-auto-rows: 1fr;
        grid-column-gap: 25px;
    }

    .mobile-shape {
        margin-top: 70px;
    }

    .flex-grid4,
    .flex-grid4.landing,
    .flex-grid2.landing {
        grid-template-columns: 1fr 1fr !important;
    }

    .flex-grid2 {
        grid-template-columns: 1fr !important;
    }

    .payment-flex-grid2 {
        padding-top: 50px !important;
    }

    .footer-menu {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }

    .btn-try-our {
        margin: 15px 70px !important;
    }

    .title-bg.why-us {
        padding: 30px 70px !important;
    }

    .title-shadow.why-us {
        margin-top: 150px !important;
    }
}

@media screen and (max-width: 768px) {
    .flex-grid4.gender {
        grid-template-columns: 1fr 1fr !important;
        grid-auto-rows: auto !important;
    }

    .justify-footer > div {
        display: inline !important;
    }

    .flex-grid2.landing {
        grid-template-columns: 1fr !important;
    }

    .hipaa-stripe {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .flex-grid3 {
        grid-template-columns: 1fr 1fr !important;
    }

    .justify-rl > div.btn-txt {
        display: inline !important;
    }

    div.line {
        margin: 35px auto !important;
        width: 70px !important;
        border-top: 2px solid #fff !important;
        background-color: #fff !important;
    }

    .btn-try-our {
        margin: 15px 30px !important;
    }

    .title-bg.why-us {
        padding: 30px !important;
    }
}

@media screen and (max-width: 600px) {
    div.line {
        margin: 0 auto;
        text-align: center !important;
    }
    .whole-body-mW {
        padding: 20px 15px !important;
    }

    .flex-grid4,
    .flex-grid3,
    .flex-grid4.landing {
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
    }

    .flex-grid3.footer {
        grid-template-columns: 1fr 1fr !important;
    }

    .payment-flex-grid2 {
        padding-top: 80px !important;
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
    }

    .title-shadow.why-us {
        width: 100% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width: 480px) {
    .flex-grid4.gender {
        grid-template-columns: 1fr !important;
    }

    .footer-menu {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .tele-img {
        margin: 0 auto !important;
        width: 100% !important;
        max-width: 100% !important;
        text-align: center !important;
    }

    .flex-grid3.footer {
        grid-template-columns: 1fr !important;
    }

    .btn-try-our {
        margin: 15px 5px !important;
    }

    .btn-common {
        margin: 20px 0 !important;
    }

    .title-bg.why-us {
        padding: 15px !important;
    }
}
/**
* landing page css
*/
* {
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
    transition: all .3s ease-out;
    /**
     * Dimension
     */

    font-size: inherit;

    /**
     * Color
     */
    --col-heavyDark: #0CABC7;
    --col-bttonAndLink: #43CD43;
    --col-paragraphBg: #F4B41A;
    --col-lightColor: #ABF2D3;
    --col-foreGround: #F1FAFA;
    --col-darkBlue: #143D59;
    --col-browny: #88862B;
    --col-light-bg: rgba(196, 196, 196, 0.21);
    --col-part-bg: linear-gradient(90deg, #00CED1 0%, #00DD92 100%);

    --col-title: #4cb5ff;
    --col-lightBlack: #0f0f0f;
    --col-black: #262829;
    --col-title26: #1f1f1f;

    --col-title-blue: #1c85bc;
    --col-desc: #42474c;
    --col-txt-title: #1e2428;
    --col-brown: #362946;
}

body {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;

    font-size: 16px;
    background-color: #ffffff;
    color: #ffffff;
    text-align: center;
    min-width: 280px;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #0002;
}

::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    border-radius: 4px;
    background-color: #0004;
}
.whole-body-mW {
    margin: 0 auto;
    padding: 0 50px;
    width: 100%;
    max-width: 1300px;
    text-align: center;
    /*z-index: 3000;*/
}

.whole-body-mW.full-width {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: unset;
    text-align: center;
}

/**
 * Font Size and Color
 */
.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.mt-20 {
    margin-top: 20px;
}
.txt-bold {
    font-weight: bold;
}

.txt-italic {
    font-style: italic;
}

.txt-12 {
    font-size: 12px;
    line-height: 14px;
}

.txt-14 {
    font-size: 14px;
    line-height: 16px;
}

.txt-16 {
    font-size: 16px;
    line-height: 19px;
}

.txt-18 {
    font-size: 18px;
    line-height: 24px;
}

.txt-20 {
    font-size: 20px;
    line-height: 23px;
}

.txt-22 {
    font-size: 22px;
    line-height: 38px;
}

.txt-24 {
    font-size: 24px;
    line-height: 28px;
}

.txt-26 {
    font-size: 26px;
    line-height: 30px;
}

.txt-30 {
    font-size: 30px;
}
.txt-32 {
    font-size: 32px;
    line-height: 37px;
}

.txt-36 {
    font-size: 36px;
    line-height: 42px;
}

.txt-38 {
    font-size: 38px;
    line-height: 42px;
}

.txt-42 {
    font-size: 42px;
    line-height: 49px;
}

.txt-48 {
    font-size: 48px;
    line-height: 56px;
}

.txt-62 {
    font-size: 62px;
    line-height: 75px;
}

.col-heavyDark {
    color: var(--col-heavyDark);
}

.col-lightColor {
    color: var(--col-lightColor);
}

.col-buttonAndLink {
    color: var(--col-title);
}

.col-darkBlue {
    color: var(--col-darkBlue);
}

.col-paragraphBg {
    color: var(--col-paragraphBg);
}

.col-nWhite {
    color: #f3f3f3;
}
/**
 * sample Color
 */
.col-title {
    color: var(--col-title);
}

.col-lightBlack {
    color: var(--col-lightBlack);
}

.col-black {
    color: var(--col-black);
}

.col-title26 {
    color: var(--col-title26);
}

.col-txt-title {
    color: var(--col-txt-title);
}

.col-desc {
    color: var(--col-desc);
}

.col-blue {
    color: var(--col-title-blue);
}

/**
 * Header
 */
.bg-cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.landing-body {
    margin: 0;
}

.landing-header {
    background-color: #262626;
    height: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: white;
    opacity: 1;
}

.btn-underLine {
    text-decoration: none !important;
    color: white !important;
}

.underLine {
    text-decoration: none !important;
    color: #333 !important;
}

a {
    text-decoration: none;
}

select {
    -webkit-appearance: none;

    border: 1px solid var(--col-lightColor);
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    background-color: var(--col-foreGround);

    background-image: url(/static/media/iconmonstr-arrow-65.cea5e582.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 12px;

    outline: none;
    transition: all .15s linear;
}

/**
 * Footer
 */
.landing-footer {
    background-color: #000000;
    height: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: white;
    opacity: 1;
}

.justify-rl > div {
    display: flex;
    justify-content: space-between;
}

.justify-footer > div {
    display: flex;
    justify-content: space-between;
}

.justify-center {
    display: flex;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

.un-between {
    display: flex;
    justify-content: flex-end;
}
.flex-grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 25px;
}

.flex-grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 25px;
}

.flex-grid4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
}

.flex-grid4.landing {
    grid-column-gap: 0;
}

.video-communication {
    padding: 0 30px 40px;
    text-align: left;
}

.image-size {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
}

.meet-online-bg1,
.meet-online-bg2,
.meet-online-bg3,
.meet-online-bg4 {
    height: 250px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.meet-online-bg1 {
    background-image: url(/static/media/link-1.dac3ba82.jpg);
}

.meet-online-bg2 {
    background-image: url(/static/media/link-2.304aeed3.jpg);
}

.meet-online-bg3 {
    background-image: url(/static/media/link-3.32d7bf9e.jpg);
}

.meet-online-bg4 {
    background-image: url(/static/media/link-4.a85132e6.jpg);
}

.bg-bound1,
.bg-bound2,
.bg-bound3,
.bg-bound4 {
    width: 100%;
    border: none;
}

.bg-bound1 {
    border-top: 6px solid #8767b6;
}

.bg-bound2 {
    border-top: 6px solid #66a9d8;
}

.bg-bound3 {
    border-top: 6px solid #63d2af;
}

.bg-bound4 {
    border-top: 6px solid #ffffff;
}

.grid4-bg1 {
    background-color: #2a1f36;
}

.grid4-bg2 {
    background-color: #2e243e;
}

.grid4-bg3 {
    background-color: #322946;
}

.grid4-bg4 {
    background-color: #392946;
}

.take-online-center {
    justify-content: center;
    text-align: center;
}
.mouse-cursor {
    cursor: pointer;
}

.align-l {
    text-align: left;
}

.align-r {
    text-align: right;
}

.align-c {
    text-align: center;
}

/**
 * Click button Array
 */
.product-btn {
    margin-right: 11px;
    padding: 15px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
}

.product-btn:hover {
    color: #ffffff;
    background-color: var(--col-title-blue);
}

.help-center-align {
    margin: 50px auto;
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    text-align: center;
}

.btn-search {
    background-color: var(--col-title-blue);
}

/**
 * snip
 */

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
            animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinning-curtain {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0006;
    z-index: 3000;
}
/**
 * Common Button
 */
.btn-common {
    margin: 20px;
    padding: 15px 40px;
    border: 1px solid var(--col-title);
    border-radius: 4px;
    transition: all .15s linear;
    background-color: #1c85bc;
}

.btn-try-our {
    margin: 15px 80px;
    padding: 15px 40px;
    border: 1px solid var(--col-title);
    border-radius: 4px;
    transition: all .15s linear;
    background-color: #1c85bc;
}

.btn-request-b {
    border: 1px solid #fff;
    color: var(--col-title-blue);
    background-color: #fff;
}

.btn-try-our:hover,
.btn-common:hover {
    border: 1px solid #1c85bc;
    background-color: var(--col-title-blue);
    color: #fff;
}


.payment-flex-grid2 {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 57px;
}

.btn-header-common,
.btn-header-common-desc {
    margin: 0 auto;
    padding: 10px 30px;
    width: 100%;
    max-width: 100%;
    text-align: left;
}

.btn-txt {
    margin: 0 auto;
    width: 100%;
    max-width: 80%;
}

.general-flex {
    display: flex;
}

.auto-matching {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    text-align: center;
}

.auto-matching-view {
    margin: 0 auto;
    width: 100%;
    height: 220px;
    text-align: center;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
}

.btn-bttonAndLink {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid var(--col-title-blue);
    border-radius: 4px;
    padding: 10px 20px;
    min-height: 40px;
    color: #ffffff;
    background-color: var(--col-title-blue);
    transition: all .15s linear;
}

.btn-bttonAndLink:hover {
    color: var(--col-title-blue);
    background-color: #ffffff;
}


/**
   * CheckBox
   */
.container-event {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 25px;
    min-width: 100%;
    height: 24px;
    font-size: 0.9rem;
    color: #333;
    cursor: pointer;
}

.container-event input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkMark {
    position: absolute;
    border: 1px solid #d9d9d9;
    top: 5px;
    left: 0;
    height: 17px;
    width: 17px;
    border-radius: 2px;
    background-color: #ffffff;
}

.checkMark:hover {
    border: 1px solid #1890FF;
}

.checkMark-gray {
    background-color: #e7e7f3;
}

.container-event:hover input ~ .checkMark,
.container-event input:checked ~ .checkMark {
    border-color: #1890FF;
    background-color: #1890FF;
}

.checkMark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-event input:checked ~ .checkMark:after {
    display: block;
}

.container-event .checkMark:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(35deg);
}

.footer-menu {
    padding-right: 100px;
    padding-left: 100px;
}

.text-nowrap {
    min-width: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-filter {
    min-width: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}


div.lineGreen,
div.lineWhite,
div.lineBlue {
    margin-bottom: 30px;
    width: 50px;
    border: none;
    border-top: 2px solid #fff;
    background-color: #fff;
}

div.line {
    margin: 35px 0;
    border-top: 2px solid #fff;
    background-color: #fff;
    max-width: 70px;
    width: 100%;
}

div.lineGreen {
    margin: 40px auto 0;
    width: 70px;
    border-top: 2px solid #63d2af;
    background-color: #63d2af;
}

div.lineGreen-left {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 70px;
    border-top: 2px solid #63d2af;
    background-color: #63d2af;
    text-align: left;
}

div.lineBlue {
    margin: 35px auto 20px;
    width: 70px;
    border-top: 2px solid var(--col-title-blue);
    background-color: var(--col-title-blue);
}

div.lineWhite {
    margin: 10px auto 10px;
    width: 70px;
    border-top: 2px solid #fff;
    background-color: #fff;
}

.title-shadow {
    text-shadow: 0 0 32px #000;
}

.title-shadow.why-us {
    margin-top: 100px;
}

.justify-center.why-us {
    justify-content: left;
}

.title-bg.why-us {
    padding: 20px 70px;
}

.therapy-desc.why-us {
    padding: 30px 60px;
}
    /**
     * Toast
     */
.Toastify__toast {
    padding: 8px 16px !important;
    border-radius: 4px !important;
    max-height: 300px !important;
    background-color: #AFFDEE !important;
    color: #873C23 !important;
}

.Toastify__progress-bar {
    height: 0 !important;
}

.Toastify__close-button {
    opacity: 1 !important;
}

.txt-line {
    line-height: 2;
}

.line-36 {
    line-height: 30px;
}


/**
 * ChatBot
 */
.bot-footer,
.slide-bot-footer {
    display: flex;
    position: fixed;
    top: calc(100vh - 40px);
    padding-right: 40px;
    border-top: 1px solid #00DD92;
    border-bottom: 1px solid #00DD92;
    height: 40px;
    width: 100%;
    background-color: #fffff4;

    justify-content: flex-end;
    align-items: center;
    z-index: 3000;
}
.bot-footer {
    max-width: calc(100% - 220px);
}
.slide-bot-footer {
    max-width: 100%;
}

@media screen and (max-width: 992px) {
    .bot-footer,
    .slide-bot-footer {
        padding-right: 20px !important;
    }
    .chatbot-form {
        right: 20px !important;
    }
}
@media screen and (max-width: 600px) {
    .bot-footer {
        max-width: 100% !important;
    }
}
@media screen and (max-width: 480px) {
    .bot-position {
        max-width: 280px !important;
    }
    .btn-days {
        margin-left: 5px !important;
    }
}
.chatbot-form {
    position: fixed;
    right: 40px;
    bottom: 25px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    justify-content: center;
    text-align: center;
    align-items: center;

    overflow-y: auto;
    max-height: 650px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.65);
}

.bot-position {
    max-width: 400px;
}

.btn-bot {
    display: flex;
    padding: 10px 20px;
    border: 1px solid var(--col-heavyDark);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 430px;
    height: 20px;
    color: var(--col-heavyDark);
    background-color: #fff;
    cursor: pointer;
}
.btn-bot:hover {
    opacity: 0.7;
}

.react-chatbot-kit-chat-input {
    max-width: 100% !important;
}
.react-chatbot-kit-chat-header {
    padding: 20px 12px !important;
    background-color: #00dc94 !important;
    font-size: 18px !important;
    color: #fff !important;
}
.react-chatbot-kit-user-avatar-container {

}
.icon-bot {
    margin-right: 7px;
    width: 20px;
}

.option-button {
    margin-bottom: 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 15px;
    outline: none;
    background-color: #abf2d3;
    color: var(--col-browny);
    cursor: pointer;
}

.under-line {
    text-decoration: underline;
    color: var(--col-paragraphBg);
}
.under-line:hover {
    color: var(--col-heavyDark);
}

.desc-index {
    z-index: 3000;
    opacity: 1;
    background-color: #fff;
}

.description {
    margin-right: 10px;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
}
.bot-arrow {
    width: 15px;
}
.react-chatbot-kit-chat-header.header-bot {
    padding: 7px 10px !important;
}
.description:hover {
    background-color: #abf2d3;
}

.pricing-txt,
.desc-index > p {
    line-height: 2;
    text-align: left;
    font-size: 14px;
}
.pt-45 {
    padding-top: 45px;
}
.btn-selected.link-price {
    padding: 7px 25px;
    background-color: var(--col-bttonAndLink);
}
.btn-selected:hover {
    opacity: 0.7;
}

tr.article-table.chatbot-table {
    height: auto;
}
.chatbot-table > td {
    max-width: 450px;
    min-width: 80px;
}
.chatbot {
    padding-top: 40px !important;
}

.question-answer {
    padding-bottom: 20px;
}

.question-answer > textarea {
    max-width: 100%;
    color: #000;
}

input.react-chatbot-kit-chat-input {
    margin-bottom:  0 !important;
}
.react-chatbot-kit-chat-btn-send {
    width: 70px !important;
    outline: none !important;
}
input.react-chatbot-kit-chat-input::-webkit-input-placeholder {
    font-size: 0.85rem !important;
}
input.react-chatbot-kit-chat-input:-ms-input-placeholder {
    font-size: 0.85rem !important;
}
input.react-chatbot-kit-chat-input::-ms-input-placeholder {
    font-size: 0.85rem !important;
}
input.react-chatbot-kit-chat-input::placeholder {
    font-size: 0.85rem !important;
}

/**
 * Footer page
 */

@media screen and (max-width: 480px){
    .btn-contact-us,
    .social-icon6 {
        width: 100% !important;
        max-width: 100% !important;
        text-align: center !important;
    }
}
.footer-pd {
    padding: 100px 0 80px;
}

.footer-bg {
    background-color: #fff;
}

.txt-align {
    text-align: left;
    line-height: 3;
}

.btn-contact-us {
    margin-top: 20px;
    margin-bottom: 32px;
    padding: 0 35px;
    border: 1px solid var(--col-title-blue);
    border-radius: 4px;
}

.btn-contact-us:hover {
    color: #ffffff;
    background-color: var(--col-title-blue);
}

.contact-align {
    display: flex;
    vertical-align: middle;
    align-items: center;
    text-align: left;
}

.icon-mr {
    margin-right: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.stripe-pt {
    padding-top: 85px;
}

.hipaa-stripe {
    padding-right: 29px;
}

.info-fixed {
    position: fixed;
    z-index: 3000;
    bottom: 30px;
    left: 50px;
    color: black;
}
/**
 * Header Component
 */
@media screen and (max-width: 1200px){
    .btn-navbar {
        margin-left: 15px !important;
    }
}
@media screen and (max-width: 992px) {
    .header-height {
        background-color: #ffffff !important;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) !important;
    }

    .btn-navbar {
        display: none !important;
    }

    .btn-navbar.price {
        display: flex !important;
        justify-content: space-between !important;
    }

    .mobile-menu {
        display: block !important;
    }

    .navbar-menu, .secondMenu {
        display: flex !important;
    }
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 600px) {
    .tablet-shown {
        display: none !important;
    }

    .phone-shown {
        display: block !important;
    }
}

@media screen and (max-width: 480px) {
    .logo-mw {
        padding: 15px !important;
    }
}

.header-height {
    height: 90px;
    background-color: #fff2;
}

.header-body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3000;
    background-color: #0002;
    /*background-image: linear-gradient(180deg, #fff8 50%, transparent 100%);*/
}

.header-body .header-logo {
    padding: 4px 0;
    height: 100%;
}

.header-body .header-logo img {
    height: 50px;
    object-fit: contain;
}

.header-body.white {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #3333;
    opacity: 0.95;
}

.header-body.white .btn-navbar {
    color: #333;
}

.logo-mw {
    padding: 15px 30px;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
}

.btn-navbar {
    display: flex;
    margin-left: 20px;
    padding: 10px;

    border-radius: 4px;
    transition: none;
}

.btn-navbar:hover {
    color: #fff !important;
    background: #43CD43;
}

.btn-filter {
    display: flex;
    margin-top: 20px;
    padding: 10px 10px 10px 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-size: 18px;

    border-bottom: 1px solid var(--col-lightColor);
    transition: none;
}

.btn-filter:hover {
    color: #fff !important;
    background: #ccc;
}

.flex-filter {
    display: flex;
    justify-content: space-between;
}


.dropdown-filter {
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    border-radius: 6px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transition: all 0.5s ease-out;
    font-size: 14px;

    text-align: left;
    z-index: 1;
}
.filter-item {
    padding: 10px;
}
.filter-item:hover {
    background-color: #ccc;
}
.dropdown:hover .dropdown-filter {
    display: block;
}

.sign-border {
    padding: 6px 10px;
    border: 2px solid #43CD43;
    /*background-color: #43CD43;*/
}
.btn-sign {
    padding: 6px 10px;
    border: 2px solid #43CD43;
    background-color: #43CD43;
}
.btn-sign:hover,
.sign-border:hover {
    opacity: 0.7;
    background-color: #43CD43;
}

.bg-signUp {
    color: #fff !important;
    background: var(--col-title);
}

.mobile-menu {
    display: none;
}

@-webkit-keyframes ami {
    from { transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}

@keyframes ami {
    from { transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}

.trans-menu {
    transform-origin: 0 0;
    -webkit-animation: ami 0.3s ease-out;
            animation: ami 0.3s ease-out;
}

.menu-hidden {
}

.hide {
    display: none;
}

.menu-container {
    position: absolute;
    width: 100%;
    max-width: 100%;
}

.navbar-menu,
.secondMenu {
    display: none;
    z-index: 3000;
    width: 100%;
    max-width: 100%;
    height: 70px;
    background-color: #fff;
    color: #333;
}

.navbar-menu:hover {
    background-color: #eee;
}

.btn-underLine:first-child .navbar-menu {
    border-top: 1px solid #abf2d3;
}

.secondMenu {
    height: 40px;
    background-color:  #fff;
}

.secondMenu:hover {
    background-color: #eee;
}

.menu-selectedBg {
    background-color: #eee;
}

.btn-navbar-bb {
    border-bottom: 1px solid var(--col-lightColor);
}

.tablet-shown {
    display: block;
}

.phone-shown {
    display: none;
}

/**
 * dropdown
 */
.dropdown {
    position: relative;
    display: inline-block;
    height: 40px;
    color: #fff;
}

.dropdown-content {
    display: none;
    position: absolute;
    /*padding: 0 16px;*/
    top: 40px;
    left: 0;
    border-radius: 6px;
    background-color: #fff;
    min-width: 250px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transition: all 0.5s ease-out;

    text-align: left;
    z-index: 1;
}

.dropdown-content.resource {
    min-width: 150px;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.menu-txt {
    padding: 10px 10px;
    width: 100%;
    color: #333;
}

.menu-txt:hover {
    /*color: var(--col-darkBlue);*/
    background-color: #eee;
}

.pl {
    padding-left: 30px;
}
.flex-more {
    display: flex;
    justify-content: space-between;
}
.drop-icon {
    padding-left: 10px;
    width: 22px;
}

.dropdown-icon {
    position: absolute;
    right: 20px;
}

.down-up-size {
    width: 20px;
    height: 20px;
}

/*@import "blog.css";*/
/*@import "blogArticle.css";*/
/*@import "directory.css";*/
/*@import "directorySearch.css";*/
/*@import "featuresPage.css";*/
/*@import "helpCenter.css";*/
/*@import "home.css";*/
/*@import "pricing.css";*/
/*@import "viewProvider.css";*/
/*@import "landing.css";*/



/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

/**
 * Blog Page
 */
@media screen and (max-width: 1200px){
    .btn-continue {
        max-width: 100% !important;
    }
}

@media screen and (max-width: 992px){
    .btn-continue {
        padding: 10px !important;
    }

    .blog-flex-gaps.flex-grid3 {
        grid-template-columns: 1fr 1fr !important;
    }
}

@media screen and (max-width: 768px){
}

@media screen and (max-width: 600px){

}

@media screen and (max-width: 480px){
    .blog-flex-gaps.flex-grid3 {
        grid-template-columns: 1fr !important;
    }

    .blog-grid-p1,
    .title-height,
    .blog-grid-p2 {
        min-height: auto !important;
    }

    .blog-grid-p1 {
        padding-bottom: 20px;
    }
}

/**
 * main css
 */
.blog-cover-bg {
    position: relative;
    padding-bottom: 59px;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 360px;

    background-image: url(/static/media/blog-cover-bg.717a29a7.png);
    background-color: #f3f5f7;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.select-topic {
    position: absolute;
    top: 80%;
    margin-right: 10px;
    bottom: 60px;
}

select.country-option {
    padding: 9px 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    min-width: 250px;
    width: 100%;
    outline: none !important;
    color: #333;
}

.select-contact {
    width: calc(50% - 10px);
}

.give-us {
    margin: 0 auto;
    line-height: 2;
    width: 75%;
    align-items: center;
}
.blog-grid-mt {
    margin-top: 40px;
}

.blog-header-body {
    padding-top: 68px;
}

.blog-flex-gaps {
    grid-column-gap: 40px;
}

.blog-img-size {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 286px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.blog-img-size:hover {
    transform: scale(1.05);
    box-shadow: 0 0 16px 2px #add;
    z-index: 1000;
    opacity: 0.6;
}

.blog-grid-p1 {
    padding-top: 23px;
    min-height: 60px;
}

.title-height {
    min-height: 75px;
}

.txt-break {
    word-break: break-all;
}

.txt-word {
    word-break: break-word;
}

.blog-home:hover {
    color: var(--col-title-blue);
}
.blog-grid-p2 {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.7;
    min-height: 155px;
}

.btn-continue {
    display: flex;
    margin-top: 19px;
    padding: 15px 40px;
    border: 1px solid var(--col-title-blue);
    border-radius: 4px;
    max-width: 70%;
    color: var(--col-title-blue);
    cursor: pointer;
}

.btn-continue:hover {
    background-color: var(--col-title-blue);
    color: #ffffff;
}

/**
 * Blog Article Page
 */
@media screen and (max-width: 992px){
    .comment-box {
        padding-left: 0 !important;
    }
    .comment-l {
        padding-left: 0 !important;
    }
    .pair-arrow-txt {
        display: none !important;
    }

    .justify-center.details {
        display: unset !important;
    }
    .readers-likes {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .general-nataly {
        display: unset !important;
    }

    .general-nataly.comment {
        padding-left: 0 !important;
    }

    .photo-article {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px){
    .btn-comment-edit {
        margin-left: 0 !important;
        margin-bottom: 30px !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .comment-box {
        padding-bottom: 0 !important;
    }
    .blog-flex-grid2 {
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
    }

    .popular-posts {
        margin-top: 50px !important;
    }

    .btn-txt {
        max-width: 100% !important;
    }

    .justify-center.readers {
        justify-content: left;
        padding-left: 10px;
        padding-bottom: 20px;
    }

    .flex-space.details {
        display: flex !important;
        justify-content: space-between !important;
    }

    .btn-blog-login {
        margin-top: 20px;
    }
}

@media screen and (max-width: 600px){
    .client-login-bg {
        height: 150px !important;
    }

    .btn-blog-login {
        margin-top: 30px;
        width: 100% !important;
        max-width: 100% !important;
    }

    .general-nataly {
        padding-left: 0 !important;
    }

    .justify-left.details {
        justify-content: left !important;
    }
}

@media screen and (max-width: 480px){
    .flex-edit {
        display: inline-block !important;
        width: 100%;
    }

    .btn-details {
        margin: 10px 0 !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    .flex-space.details {
        display: unset !important;
    }

    .justify-center.details-view {
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: left !important;
    }

    .justify-left.details {
        display: inline-block !important;
        justify-content: unset !important;
    }

    .justify-center.helpful {
        padding-top: 20px;
        justify-content: left;
    }
}


.blog-article-bg {
    height: 90px;
    background-color: #0002;
}

.online-therapy {
    padding-top: 47px;
    padding-bottom: 52px;
}

.blog-flex-grid2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 49px;
}

hr {
    padding-top: 22px;
    margin-bottom: 22px;
    border: none;
    border-bottom: 1px solid var(--col-lightColor);
    width: 100%;
}

.date-rl {
    padding: 0 5px;
}

.by-comment {
    padding-left: 5px;
    padding-right: 5px;
}

.general-nataly {
    display: flex;
    padding-left: 20px;
    justify-content: left;
    align-items: center;
}
.add-icon {
    margin-left: 40px;
    margin-right: 20px;
    max-width: 17px;
    min-width: 15px;
}
.blog-bg {
    width: 100%;
    max-width: 100%;
    height: 421px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: unset;
}

.photo-comment {
    margin-right: 20px;
    width: 50px;
    height: 50px;
}

.photo-article {
    margin-right: 20px;
    border-radius: 50%;
    width: 70px;
    height: 70px;
}

.txt-lorem-pt {
    padding-top: 23px;
}

.blog-article-p1 {
    padding-top: 70px;
    padding-bottom: 17px;
}

.social-icon {
    padding-right: 10px;
}

.link-arrow {
    padding-top: 90px;
    padding-bottom: 52px;
}

.pair-arrow-r {
    padding-right: 10px;
}

.pair-arrow-l {
    padding-left: 10px;
}

.client-login-bg {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 128px;
    background-color: #57b0d2;
}

.btn-blog-login {
    padding: 15px 40px;
    border: 1px solid var(--col-title-blue);
    border-radius: 4px;
    color: var(--col-title-blue);
    background-color: #fff;
}

.btn-blog-login:hover {
    background-color: var(--col-title-blue);
    color: #fff;
}

.blog-social-share {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 1200px;
}

.comment-box {
    padding-left: 20px;
    padding-bottom: 30px;

    background-color: #fff;
}

.box-width {
    width: 100%;
    max-width: calc(100% - 150px);
}

.flex-edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pair-arrow-txt:hover {
    color: var(--col-bttonAndLink);
}
.comment-l {
    padding-left: 20px;
    padding-bottom: 20px;
}

.btn-details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 4px;
    width: 180px;
    height: 40px;
    text-align: center;
    background-color: var(--col-bttonAndLink);
}

.btn-comment-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 4px;
    width: 100%;
    max-width: 100px;
    height: 40px;
    text-align: center;
    background-color: var(--col-bttonAndLink);
}

.btn-details:hover,
.btn-comment-edit:hover {
    opacity: 0.7;
}

.btn-details.back {
    background-color: var(--col-heavyDark);
}

.small-btn {
    width: 100px;
}

.border-box {
    margin-right: 20px;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid var(--col-bttonAndLink);
    min-height: 50px;
    width: 100%;

    height: auto;
    outline: none;
    background-color: #fff;
}

.border-box:hover {
    background-color: #f1f1f1;
}

.recent-post:hover {
    color: var(--col-bttonAndLink);
}

.comment-border {
    border-color: #0004;
}
.comment-border::-webkit-input-placeholder {
    color: #0002;
}
.comment-border:-ms-input-placeholder {
    color: #0002;
}
.comment-border::-ms-input-placeholder {
    color: #0002;
}
.comment-border::placeholder {
    color: #0002;
}

.pt-80 {
    padding-top: 80px;
}

.col-lightBlue {
    color: #1c8fbc;
}

.comments-b {
    padding-top: 80px;
    padding-bottom: 10px;
}

.justify-left-details {
    display: flex;
    align-items: center;
}

.col-gray {
    color: grey;
}

.comment-icon {
    width: 30px;
}

.justify-icon {
    display: flex;
    justify-content: space-between;
}

.align-match {
    align-items: center;
}

.react-share__ShareButton {
    outline: none;
}

.social-icons {
    border-radius: 3px;
    margin-right: 20px;
    cursor: pointer;
}

.social-icons:hover {
    opacity: 0.6;
}

@media screen and (max-width: 1440px){
    .directory-header-position {
        right: 5% !important;
        width: 50% !important;
    }
}

@media screen and (max-width: 1200px){
    .directory-header-position {
        width: 80% !important;
    }

    .directory-header-bg {
        background-position: left 20% !important;
    }

    .update-btn-pt {
        width: 160px !important;
    }
}

@media screen and (max-width: 992px){
    .directory-header-bg {
        background-position: top 91px left 10% !important;
    }
    .directory-header-position {
        margin: 0 auto !important;
        bottom: 25% !important;
        width: 90% !important;
    }
}

@media screen and (max-width: 768px){
    .search-grid2 {
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
    }

    .therapy-span3 {
        grid-column: 1 / span 2 !important;
    }

    .flex-space {
        display: unset !important;
        justify-content: unset !important;
    }

    .category {
        width: 100% !important;
    }

    .update-btn-pt {
        margin-left: 0 !important;
        width: 40% !important;
        height: 40px !important;
    }

    .flex-grid3.flex-select-space.filter {
        display: block !important;
    }

    select.looking-for.filter {
        margin-top: 0 !important;
        width: 100% !important;
    }

    .update-btn-pt.filter {
        margin-top: 20px !important;
        width: 100% !important;
    }
}

@media screen and (max-width: 600px){
    .directory-header-bg {
        height: 480px !important;
    }

    .search-specialty {
        padding-top: 40px !important;
    }

    .seeking-needs {
        padding-bottom: 10px !important;
    }
}

@media screen and (max-width: 480px){
    .directory-header-bg {
        height: 500px !important;
        background-position: top 91px left 15% !important;
    }

    .therapy-span2,
    .therapy-span3 {
        grid-column: unset !important;
    }
    .update-btn-pt {
        margin-top: 0 !important;
    }
}

/**
 * Directory
 */

.directory-header-bg {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 380px;
    /*background-image: url("../img/directory-cover-bg.svg");*/
    background-image: url(/static/media/bg-cover-5.31d255c7.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

select.looking-for.filter {
    margin-top: 0;
}
.directory-header-position {
    position: absolute;
    bottom: 25%;
    right: 10%;
    width: 40%;
    text-shadow: 0 0 4px #3336;
}

.connect-today-pb {
    padding-top: 20px;
    padding-bottom: 20px;
    text-shadow: 0 0 4px #3336;
}

select.looking-for.directory {
    margin-right: 20px;
    width: 36% !important;

    background-color: #ffffff;
}

select.looking-for.gender {
    margin-top: 20px;
    width: 100% !important;
}

.looking-for.filter {
    margin-top: 20px;
}
input.looking-for.directory {
    padding-left: 20px !important;
    margin-right: 10px;
    width: calc(72% + 20px);
    background-color: #ffffff;
}

.btn-search-h {
    margin-bottom: 17px;
    border-radius: 4px;
    height: 40px;
    min-width: 130px;
    color: #ffffff;
    background: var(--col-title-blue);
}

.btn-search-h:hover {
    background-color: var(--col-brown);
}

@media screen and (max-width: 600px) {
    .btn-search-h {
        margin-top: 20px !important;
    }
    select.looking-for.directory {
        margin-right: 0 !important;
        width: 100% !important;
    }

    input.looking-for.directory {
        margin-right: 0 !important;
        width: 100% !important;
    }

    .flex-select-space {
        display: flex;
        flex-direction: column-reverse !important;
        justify-content: unset !important;
    }

    .directory-header-position {
        /*bottom: 10px !important;*/
    }

    .connect-today-pb {
        padding-top: 15px !important;
        padding-bottom: 10px !important;
    }

    .directory > .justify-rl > div {
        display: inline !important;
        justify-content: unset !important;
    }
}

@media screen and (max-width: 480px) {
    .btn-search-h {
        margin-top: 0 !important;
    }
}

.search-specialty {
    padding-top: 40px;
}

.bg-specialty {
    background-color: #57b0d2;
}

.col-directory-txt {
    color: var(--col-title-blue);
}
.seeking-needs {
    padding-top: 40px;
    padding-bottom: 40px;
}

.search-grid3-gaps {
}

.therapy-span2 {
    grid-column: 1 / span 2;
}

.therapy-span3 {
    grid-column: 1 / span 3;
}

.acute-chronic {
    padding: 20px;
    height: 240px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.acute-chronic:hover {
    transform: scale(1.04);
    box-shadow: 0 0 4px 0 #666;
    z-index: 1000;
}

.search-img-txt {
    text-align: left;
    width: 90%;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    text-shadow: 0 0 16px #000;
    color: #ffffff;
}

.img-top-p {
    margin-bottom: 94px;
}

/**
 * Directory Search
 */
.directory-header-pt {
    padding-top: 54px;
}

.flex-select-space {
    display: flex;
    justify-content: space-between;
}

.filter-init:hover {
    color: var(--col-title-blue);
}
._2iA8p44d0WZ-WqRBGcAuEV {
    border: none !important;
    border-bottom: 1px solid var(--col-lightColor) !important;
    width: 100%;
}

._3vt7_Mh4hRCFbp__dFqBCI input::-webkit-input-placeholder {
    color: #000;
}

._3vt7_Mh4hRCFbp__dFqBCI input:-ms-input-placeholder {
    color: #000;
}

._3vt7_Mh4hRCFbp__dFqBCI input::-ms-input-placeholder {
    color: #000;
}

._3vt7_Mh4hRCFbp__dFqBCI input::placeholder,
._3vLmCG3bB3CM2hhAiQX1tN {
    color: #000;
}

._3vt7_Mh4hRCFbp__dFqBCI {
    width: auto !important;
    min-width: calc(50% - 10px);
}
.multi-select {
    color: #000 !important;
}
#search_input {
    margin-bottom: 5px;
}

select.looking-for {
    -webkit-appearance: none;
    color: var(--col-darkBlue);
}

@media screen and (max-width: 600px) {
    select.looking-for {
        width: 100% !important;
    }
}

.right-p {
    margin-right: 20px;
}

.left-p {
    margin-left: 20px;
}

select.looking-for {
    margin-bottom: 17px;
}

input.looking-for {
    margin-bottom: 0;
}
input.looking-for,
select.looking-for {
    padding-left: 20px;
    border: none;
    border-bottom: 1px solid var(--col-lightColor);
    border-radius: unset;
    width: 100%;
    max-width: 100%;
    height: 40px;
    outline: none;
    transition: all .15s linear;
    background-color: #fff;
}

select.looking-for::-webkit-input-placeholder {
    font-size: 16px;
    color: var(--col-darkBlue);
}

select.looking-for:-ms-input-placeholder {
    font-size: 16px;
    color: var(--col-darkBlue);
}

select.looking-for::-ms-input-placeholder {
    font-size: 16px;
    color: var(--col-darkBlue);
}

select.looking-for::placeholder {
    font-size: 16px;
    color: var(--col-darkBlue);
}

input.looking-for::-webkit-input-placeholder {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25);
}

input.looking-for:-ms-input-placeholder {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25);
}

input.looking-for::-ms-input-placeholder {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25);
}

input.looking-for::placeholder {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25);
}

.filter-label {
    padding-top: 20px;
    padding-bottom: 10px;
}

.down-up-pl {
    padding-left: 10px;
}

.flex-space {
    display: flex;
    justify-content: space-between;
}

.category {
    width: 100%;
}

/**
 * Making toggle
 */
.collapsible {
    color: white;
    cursor: pointer;
    padding: 18px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}

.content {
    padding: 0 18px;
}

.update-btn-pt {
    margin-left: 20px;
    width: 200px;
    height: 50px;
}

select.sort-by-width {
    margin-top: 15px;
    width: 250px !important;
    color: var(--col-darkBlue);
}

@media screen and (max-width: 600px) {
    select.sort-by-width {
        width: 100% !important;
    }

    .right-p {
        margin-right: 0 !important;
    }

    .left-p {
        margin-left: 0 !important;
    }

}

.michael-pl {
    padding-top: 20px;
    padding-left: 20px;
}

.directory-grid-gaps {
    padding-top: 15px;
    grid-column-gap: 15px;
}

.new-york-ptb {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ring-pr {
    padding-right: 5px;
    padding-bottom: 40px;
}
/**
 * Features Page
 */
@media screen and (max-width: 1440px){

}

@media screen and (max-width: 1200px){
    .device-img-space {
        padding: 0 100px !important;
    }

    .device-img-text-space {
        padding: 90px 150px 0 !important;
    }
}

@media screen and (max-width: 992px){
    .contact-width {
        width: 80% !important;
    }
    .features-flex-grid2 {
        padding-bottom: 20px !important;
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
    }

    .contact-flex-grid2 {
        padding-bottom: 20px !important;
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
    }

    .online-grid2-l {
        display: grid;
        padding-bottom: 20px !important;
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
        grid-column-gap: 30px !important;
    }

    .online-grid2-r {
        display: grid;
        padding-bottom: 20px !important;
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
        grid-column-gap: 30px !important;
    }

    .features-payment-pb1 {
        padding-top: 20px !important;
    }

    .device-img {
        max-width: 80px !important;
    }

    .align-r.app-store,
    .align-l.app-store {
        text-align: center !important;
    }
}

@media screen and (max-width: 768px){
    .device-title {
        padding-left: 0 !important;
    }

    .device-img-space {
        padding: 50px 0 0 !important;
    }

    .device-img-text-space {
        padding: 50px 0 0 !important;
    }
}

@media screen and (max-width: 600px){
    .features-payment-bgColor {
        height: 540px !important;
    }
}

@media screen and (max-width: 480px){
    .device-img {
        padding: 5px !important;
        max-width: 65px !important;
    }

    .social-img {
        width: 100% !important;
        max-width: 300px !important;
    }

    .contact-width {
        width: 95% !important;
    }

    .select-contact,
    .btn-contact {
        width: 100% !important;
    }
}

/**
 * main css
 */
.features-payment-bgColor {
    margin: 0 auto;
    width: 100%;
    height: 400px;
    /*background-image: linear-gradient(90deg, #00CED1 0%, #00DD92 100%);*/
    background-image: url(/static/media/iStock-1216915208.a0bcb8ad.jpg);
    background-size: cover;
    background-position: center center;
    text-align: center;
}

.features-header-pt {
    padding-top: 70px;
}

.features-flex-grid2 {
    display: grid;
    padding-bottom: 70px;
    grid-template-columns: 7fr 5.5fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 19px;
}
.contact-flex-grid2 {
    display: grid;
    padding-top: 30px;
    padding-bottom: 20px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 19px;
}
.contact-width {
    margin: 30px auto;
    width: 60%;
}
.react-datepicker__input-container {
    width: 250px !important;
}
.meeting-duration {
    display: flex;
    margin: 0 auto;
    width: 30%;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 4px;
    border: 1px solid var(--col-title-blue);
}
.empty-img {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 420px;
    text-align: center;
    background-color: #0002;
}

.features-payment-pb1 {
    padding-bottom: 13px;
}

.features-payment-pb2 {
    padding-bottom: 15px;
}

.features-payment-pb3 {
    padding-bottom: 5px;
}

.sign-up-for {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 128px;
    text-align: center;
    background-color: #57b0d2;
}

.justify-center-btn {
    display: flex;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

/**
 * Features Online Sessions
 */
.online-grid2-l {
    display: grid;
    padding-bottom: 81px;
    grid-template-columns: 2.3fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 41px;
}

.online-grid2-r {
    display: grid;
    padding-bottom: 81px;
    grid-template-columns: 1fr 2.3fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 57px;
}

.empty-img-inversion {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: 315px;
    text-align: center;

    background-color: #0002;
}

.title-ptb {
    padding-top: 20px;
    padding-bottom: 20px;
}

.desc-features {
    padding-bottom: 20px;
}

/**
 * Features Devices
 */
.features-devices-bg {
    margin: 0 auto;
    width: 100%;
    height: 400px;
    text-align: center;

    background-image: url(/static/media/device-img-bg.8b81b0d3.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.device-title {
    margin-top: 120px;
    margin-bottom: 50px;
    padding-left: 100px;
}

.device-img-space,
.device-img-text-space {
    display: flex;
    justify-content: space-between;
}

.device-img-space {
    padding: 0 200px;
}

.device-img-text-space {
    padding: 0 250px;
}

.device-img {
    width: 100%;
    max-width: 120px;
}

.apple-txt-pt60 {
    padding-top: 60px;
}

.apple-txt-pt90 {
    padding-top: 90px;
}

.device-grid2-gaps {
    grid-column-gap: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

/**
 * Why Us page
 */
.Why-us-bg {
    margin: 0 auto;
    width: 100%;
    height: 400px;
    text-align: center;

    /*background-image: url("../img/why-us-img.svg");*/
    background-image: url(/static/media/why-us-img.ec8e5717.jpg);

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.why-us-gaps {
    grid-column-gap: 48px;
}

.stats-30 {
    padding-top: 27px;
}

.stats-txt-p {
    padding-top: 30px;
    padding-bottom: 97px;
}

.img-pt {
    padding-top: 30px;
}
/**
 * Help Article Page
 */

@media screen and (max-width: 1200px) {
    .help-flex-grid2 {
        grid-template-columns: 4fr 1fr !important;
    }
}

@media screen and (max-width: 992px) {
    .header-mt {
        padding-top: 160px !important;
        max-width: 70% !important;
    }

    .readers-likes {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    .header-mt {
        max-width: 85% !important;
    }

    .product-btn {
        margin-right: 5px !important;
    }

    .flex-readers-likes {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        text-align: center;
    }

    .pt-b {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .icon-p {
        padding-right: 20px !important;
    }
}

@media screen and (max-width: 600px) {
    .flex-space-icon.readers {
        justify-content: left !important;
    }

    .header-mt {
        max-width: 95% !important;
    }

    .help-article-pt {
        padding-top: 30px !important;
        padding-bottom: 50px !important;
    }

    .product-btn {
        padding: 5px 5px !important;
    }

    .flex-space-detail {
        display: unset !important;
    }

    .flex-space-detail.readers {
        display: flex !important;
    }

    .justify-left.icon {
        justify-content: left !important;
    }
}

@media screen and (max-width: 480px) {
    .flex-space-detail.readers {
        display: unset !important;
    }

    input {
        margin-bottom: 20px !important;
    }

    .help-flex-grid2 {
        display: grid;
        grid-template-columns: 1fr !important;
        grid-auto-rows: 1fr;
        grid-column-gap: 20px;
    }

    .help-article-desc {
        padding: 30px 15px !important;
    }
}

.help-article-bgColor {
    background-color: var(--col-foreGround);
}

.help-article-bg {
    padding-bottom: 70px;
    height: auto;
    /*background-image: url("../img/landing/help-center-header.png");*/
    background-image: url(/static/media/help-center-header.31de2929.png);
    background-size: cover;
    background-position: top 0 center;
    background-repeat: no-repeat;
}

.header-mt {
    margin: 0 auto;
    padding-top: 200px;
    top: 90px;
    width: 100%;
    max-width: 50%;
    min-height: 300px;
    text-align: center;
}

input {
    padding-left: 12px;
    border: none;
    width: 100%;
    max-width: 100%;
    height: 40px;
    background-color: #ffffff;
    outline: #ffffff !important;
    transition: all .15s linear;
}

::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 22px;
}

:-ms-input-placeholder {
    font-size: 16px;
    line-height: 22px;
}

::-ms-input-placeholder {
    font-size: 16px;
    line-height: 22px;
}

::placeholder {
    font-size: 16px;
    line-height: 22px;
}

.btn-find-help {
    height: 40px;
    border-radius: 4px;
    border: 2px solid #fff8;
    background-color: #0006;
    transition: all .15s linear;
}

.btn-find-help:hover {
    border: 2px solid var(--col-title-blue);
    background-color: var(--col-title-blue);
}

.help-flex-grid2 {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
}

.help-bg {
    background-color: #f1fafa;
}

.help-flex-grid2 input {
    background-color: #0006;
}

.help-article-pt {
    padding-top: 50px;
    padding-bottom: 50px;
}

.arrow-rl {
    padding: 0 10px;
}

.help-article-desc {
    margin-top: 31px;
    padding: 30px 43px 20px 40px;
    border: 1px solid var(--col-foreGround);
    background-color: #ffffff;
}

.help-article-p1 {
    padding-bottom: 14px;
}

.help-article-p2 {
    padding-bottom: 22px;
    line-height: 2;
}

.help-article-p3 {
    padding-top: 48px;
    padding-bottom: 10px;
}

.icon-l {
    padding-left: 10px;
}

.help-article-p4 {
    padding-bottom: 40px;
}

.yes-no:hover {
    color: var(--col-paragraphBg);
}

.help-article-p5 {
    margin-top: 19px;
}

.help-article-p6 {
    margin-top: 15px;
    padding: 0 15% 20px 20px;
}

.general-articles {
    padding-left: 85px;
}

.general-pt {
    padding-top: 53px;
}

/**
 * Help Center
 */
.refix-pb {
    padding-bottom: 30px;
}

.help-center-p1 {
    padding-top: 39px;
    padding-bottom: 39px;
}

.help-center-gaps {
    grid-column-gap: 42px;
}

.help-center-p2 {
    padding: 30px;
}

.help-center-p2:hover {
    transform: scale(1.02);
    box-shadow: 0 0 16px 2px #add;
    z-index: 1000;
}

.help-center-p3 {
    padding: 30px;
}

.help-center-p3:hover {
    transform: scale(1.02);
    box-shadow: 0 0 16px 2px #add;
}

.general-t {
    padding-top: 20px;
}

.content-height {
    min-height: 100px;
}

.help-need {
    padding-top: 60px;
    padding-bottom: 10px;
}

.right-place {
    padding-bottom: 50px;
}

.card-box:hover {
    transform: scale(1.02);
    box-shadow: 0 0 16px 2px #add;
    z-index: 1000;
}

.like-view {
    padding-right: 10px;
}

.icon-p {
    padding-right: 40px;
}

.flex-space-detail,
.flex-space-icon {
    display: flex;
    justify-content: space-between;
}

.help-article-p2.readers {
    padding-top: 30px;
}

.col-light-gray {
    color: #0004;
}
/**
 * Landing Page CSS
 */
@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1200px) {
    .header-bg {
        background-position: right 45% !important;
    }

    .check-width {
        max-width: 90% !important;
    }

    .practice-online {
        height: auto !important;
    }

    .connect-contents {
        margin-right: 5% !important;
    }

    .connect-clients {
        height: 550px !important;
    }
}

@media screen and (max-width: 992px){
    .title-bg {
        padding: 30px 5px !important;
        height: auto !important;
    }

    .left-header-text {
        left: 100px !important;
    }

    .connect-clients {
        height: auto !important;
        background-image: linear-gradient(270deg, #FFFFFF 20%, rgba(255, 255, 255, 0) 39.28%), url(/static/media/iStock-1262378661.91d752f0.jpg) !important;
        background-position: 0 0;
    }

    .col-responsive {
        color: #fff;
        text-shadow: 0 0 4px #000;
    }

    .connect-contents {
        margin-top: 20px;
        margin-left: 55% !important;
        margin-right: 2% !important;
    }

    .btn-start {
        margin-right: 60px !important;
        margin-left: 60px !important;
    }

    .about-btn {
        margin-bottom: 30px !important;
    }

    .bg-buttonAndLink {
        padding-bottom: 10px !important;
    }

}

@media screen and (max-width: 768px) {
    .why-us-desc {
        padding-left: 0 !important;
        max-width: 100% !important;
    }

    .title-bg {
        margin: 0 auto !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    .title-left {
        margin: 0 auto !important;
        padding-top: 30vh !important;
        padding-left: 0 !important;
        text-align: center !important;
    }

    .left-header-text,
    .btn-landing-bg {
        margin: 35px auto !important;
        width: 100% !important;
        max-width: 100% !important;
        text-align: center !important;
    }

    .therapy-desc {
        padding: 10px !important;
    }

    .btn-trial {
        margin: 20px auto !important;
        width: 100% !important;
        max-width: calc(100% - 40px) !important;
        height: 50px !important;
        text-align: center !important;
    }

    .btn-landing-bg {
        margin: 0 auto !important;
        padding-top: 0 !important;
    }

    .features-pd {
        max-width: 80% !important;
    }

    .connect-contents {
        margin-left: 5px !important;
    }

    .try-pricing {
        margin: 60px !important;
        padding: 15px 30px !important;
    }

    .btn-start {
        margin-right: 40px !important;
        margin-left: 40px !important;
        padding: 15px 30px !important;
    }

    .why-align {
        text-align: center !important;
    }
}

@media screen and (max-width: 600px) {
    .justify-left {
        display: flex!important;;
        text-align: center!important;;
        vertical-align: middle!important;;
        justify-content: center!important;;
        align-items: center!important;;
    }

    .btn-learn-features.learn {
        display: flex !important;
    }
    .left-header-text {
        text-align: center !important;
    }

    .title-bg {
        padding: 20px 25px !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    .practice-online {
        position: relative !important;
        top: 0 !important;
    }

    .try-pricing {
        margin: 60px 30px !important;
    }

    .title-left {
        padding-top: 15vh !important;
    }

    .btn-try-our {
        padding: 10px 30px !important;
    }

    .why-us-pd {
        padding: 0 20px!important;
    }

    .btn-learn-more {
        width: 100% !important;
        max-width: 100% !important;
        text-align: center !important;
    }

    .btn-learn-features {
        display: inline !important;
        margin-top: 40px !important;
        padding: 15px !important;
        width: 100% !important;
        max-width: 100% !important;
        text-align: center !important;
    }
}

@media screen and (max-width: 480px) {
    .btn-landing-bg {
        padding-left: 0 !important;
        max-width: 100% !important;
        justify-content: center !important;
    }

    .try-pricing {
        margin: 60px 10px !important;
    }

    .therapy-desc {
        left: 0 !important;
        margin: 20px auto !important;
        width: 100%;
        text-align: center !important;
    }

    .connect-clients {
        padding: 5px !important;
        background-image: linear-gradient(270deg, #FFFFFF 20%, rgba(255, 255, 255, 0) 39.28%), url(/static/media/iStock-1262378661.91d752f0.jpg) !important;
        background-position: center !important;
    }

    .connect-contents {
        margin-right: 0 !important;
    }

    .online-practice-pm {
        padding-top: 50px !important;
    }

    .online-practice-pm, .request-pm {
        display: inline !important;
    }


    .justify-center.free-trial {
        display: inline !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    .btn-learn-features.btn-start {
        display: flex !important;
        justify-content: center;
    }

    .btn-start {
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding: 15px 30px !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}

/**
 * body
 */
.header-bg {
    height: 85vh;
    background-size: cover;
    background-position: top 0  center;
    background-repeat: no-repeat;
}

.therapy-desc {
    padding: 30px 0 0 80px;
    font-style: italic;
    text-shadow: 0 0 4px #000a;
}

.left-header-text {
    text-align: left;
}

.btn-trial {
    display: flex;
    border-radius: 4px;
    width: 100%;
    max-width: 260px;
    min-width: 250px;
    height: 100%;
    max-height: 50px;
    font-size: 14px;
    color: #66a9d8;
    background-color: #fff;

    transition: all .15s linear;
}

.btn-trial:hover {
    color: #fff;
    background-color: #66a9d8;
}

/**
 * Take your practice online
 */
.take-practice {
    padding-top: 20px;
}

.connect-before {
    padding-top: 7px;
    padding-bottom: 20px;
}

.flex-grid4 {
    grid-column-gap: 40px;
}

.take-practice-tp {
    display: flex;
    min-height: 140px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.try-pricing {
    margin: 60px 80px 68px;
    padding: 15px 40px;
    border: 1px solid #1c85bc;
    border-radius: 4px;
    color: #fff;
    background-color: #1c85bc;
    transition: all .15s linear;
}

.try-pricing:hover {
    color: #1c85bc;
    background-color: #fff;
}

.online-practice-pm {
    display: flex;
}

.use-app-flex {
    display: flex;
    margin-top: 30px;
    text-align: left;
}


.bg-buttonAndLink {
    padding-bottom: 70px;
    height: auto;
    background-color: #f4f4f4;
}

.watch-icon-pd {
    padding-top: 47px;
    padding-bottom: 60px;
}

.request-pm {
    display: flex;
    padding-top: 17px;
}

/**
 * Who uses this app?
 */
.use-app {
    padding-top: 40px;
}

.app-desc {
    padding-top: 40px;
    padding-bottom: 46px;
}

.check-width {
    margin: 0 auto 80px;
    width: 100%;
    max-width: 80%;
}

/**
 * circle check box
 */
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
}

.round label:after {
    border: 2px solid var(--col-heavyDark);
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(-50deg);
    width: 10px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
    background-color: #ffffff;
    border-color: var(--col-heavyDark);
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.check-flex {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.circle-pd {
    font-size: 20px;
    margin-left: 13px;
}

.circle-pd:hover {
    color: #1e2428;
    cursor: pointer;
}

/**
 * The features you need and want
 */

.features-needs {
    padding-top: 100px;
}

.features-pd {
    margin: 20px auto;
    padding-top: 23px;
    padding-bottom: 50px;
    width: 100%;
    max-width: 50%;
    text-align: center;
}

.features-img-pb {
    padding-bottom: 16px;
}

.features-icon-desc {
    padding-top: 20px;
    line-height: 1.5;
}

.features-mb {
    margin-top: 20px;
    margin-bottom: 50px;
}

.features-icons {
    margin: 0 auto;
    width: 100%;
    max-width: 108px;
    text-align: center;
}

.refixing-gap {
    grid-column-gap: 20px;
}

/**
 * Why US?
 */
.why-us-bg {
    padding-top: 64px;
    padding-bottom: 86px;
    height: auto;
    background-image: url(/static/media/why-us-img.ec8e5717.jpg);
}

.why-us-landing-bg {
    padding-top: 64px;
    padding-bottom: 86px;
    height: auto;
    background-image: url(/static/media/why-us-cover.122db678.svg);
}

.why-us-pd {
    margin: 0 auto;
    padding: 0 50px;
    width: 100%;
    max-width: 1200px;
    text-align: left;
}

.why-us-desc {
    padding-top: 20px;
    padding-left: 44%;
    max-width: 100%;
    line-height: 1.5;
}

.btn-learn-more {
    padding: 10px 40px;
    border: 2px solid #fff;
    border-radius: 4px;
    transition: all .15s linear;
}

.btn-learn-more:hover {
    background-color: #0002;

}

.justify-left {
    display: flex;
    padding-top: 44px;
}

.title-bg {
    padding: 20px 50px;
    height: auto;
    font-style: italic;
    font-weight: 600;

    background: rgba(0, 0, 0, 0.4);
    transition: all .15s linear;
}

.title-left {
    padding-top: 32vh;
    padding-left: 0;
}

.join-pt {
    margin-top: 30px;
    margin-left: 80px;
}
.connect-clients {
    height: 500px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-image: linear-gradient(270deg, #FFFFFF 51.07%, rgba(255, 255, 255, 0) 39.28%), url(/static/media/iStock-1262378661.91d752f0.jpg);
    background-position: 0 0;
    background-size: auto 100%;
}

.connect-contents {
    margin-right: 10%;
    max-width: 1200px;
    margin-left: 50%;
    line-height: 2;
}

.btn-learn-features {
    display: flex;
    margin-top: 50px;
    padding: 15px 40px;
    border-radius: 4px;
    background-color: var(--col-brown);
    transition: all .15s linear;
}

.btn-learn-features:hover {
    background-color: var(--col-title-blue);
}

.our-customers {
    padding-top: 37px;
    padding-bottom: 37px;
    height: auto;
    /*background: linear-gradient(90deg, #00CED1 0%, #00DD92 100%);*/
    background-color: #56456c;
    opacity: 0.8;
}

.star-pt {
    padding-top: 17px;
    padding-bottom: 57px;
}

/**
 * Slider
 */

.owl-carousel .owl-item img {
    margin: 20px auto;
    width: 80% !important;
    text-align: center !important;
}

/**
 * arrow customize
 */
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #ffffff !important;
}

.header > .owl-theme .owl-dots .owl-dot.active span {
    background: #808080 !important;
}

.header > .owl-theme .owl-dots .owl-dot:hover span {
    background: #808080 !important;
}

.owl-theme .owl-nav {
    /*padding-top: 60px;*/
}

.header > .owl-theme .owl-dots .owl-dot span {
    position: relative;
    margin: -30px 10px 0;
    outline: none !important;
}

.owl-theme .owl-dots .owl-dot span {
    position: relative;
    margin: 35px 15px;
}


.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev
{
    position: absolute;
    margin-top: 0;
    border: none;
    padding-top: 15px;
    width: 40px;
    font-size: 40px !important;

    color: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.owl-carousel .owl-nav button.owl-prev {
    top: 50%;
    transform: translateY(-120%);
    left: -15px;
}

.owl-carousel .owl-nav button.owl-next {
    top: 50%;
    transform: translateY(-120%);
    right: -15px;
}

.header > .owl-carousel .owl-nav button.owl-prev {
    display: none !important;
}

.header > .owl-carousel .owl-nav button.owl-next {
    display: none !important;
}

.owl-theme .owl-dots .owl-dot span {
    display: inline !important;
}

.header > .owl-theme .owl-dots .owl-dot span {
    display: block !important;
}

.owl-theme .owl-dots .owl-dot {
    outline: none !important;
}


.owl-dots, .owl-nav {
    display: flex;
    margin:  0 !important;
    text-align: center !important;
    justify-content: center;
}
.animate-fading {
    -webkit-animation:fading 10s infinite;
            animation:fading 10s infinite
}

@-webkit-keyframes fading {
    0% {opacity:0.9} 50%{opacity:1} 100%{opacity:0.9}}

@keyframes fading {
    0% {opacity:0.9} 50%{opacity:1} 100%{opacity:0.9}}

.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

@-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}
/**
 * Check out our pricing and sign up for our free 3-day trial
 */
.check-trial-bg {
    padding-top: 100px;
    padding-bottom: 38px;
    height: auto;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%), url(/static/media/check-trial-bg.76e3e48e.svg);
}

.slide-txt-p {
    padding-top: 95px;
}

.offer-plan {
    padding-top: 20px;
}

.btn-start {
    margin-right: 80px;
    margin-left: 80px;
    padding: 15px 40px;
    transition: all .15s linear;
}

/**
 * Slider
 */
.each-fade {
    display: flex;
    width: 100%;
}

.each-fade > div {
    width: 100%;
}

.each-fade > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.each-fade p {
    width: 25%;
    font-size: 1em;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
}

.features-title:hover {
    color: var(--col-title);
}

/**
 * Shape circle button
 */

@media screen and (max-width: 992px) {
    .mobile-margin {
        margin-left: 50px !important;
    }
    .menu-circle {
        height: 650px !important;
        top: 220px !important;
    }

    .menu-item {
        margin-left: -55px !important;
        width: 160px !important;
        height: 160px !important;
    }

    .center-item {
        margin-top: -20px !important;
        margin-left: -93px !important;
        width: 240px !important;
        height: 240px !important;
    }
    .circle-item {
        width: 110px !important;
        font-size: calc(6px + 1vw) !important;
    }
}


@media screen and (max-width: 768px) {
    .menu-item {
        margin-left: -55px !important;
        width: 145px !important;
        height: 145px !important;
    }

    .center-item {
        margin-top: -20px !important;
        margin-left: -90px !important;
        width: 220px !important;
        height: 220px !important;
    }

    .medium-item {
        margin-top: -10px !important;
        margin-left: -55px !important;
        width: 460px !important;
        height: 460px !important;
    }

    .large-item {
        margin-top: -10px !important;
        margin-left: -55px !important;
        width: 480px !important;
        height: 480px !important;
    }

    .circle-btn {
        width: 170px !important;
    }

    .circle-item {
        width: 90px !important;
    }
}

@media screen and (max-width: 600px) {
    .mobile-margin {
        margin-left: 70px !important;
    }

    .menu-circle {
        height: 450px !important;
        top: 130px !important;
    }

    .menu-item {
        width: 117px !important;
        height: 117px !important;
    }

    .center-item {
        margin-top: -10px !important;
        margin-left: -75px !important;
        width: 160px !important;
        height: 160px !important;
    }

    .medium-item {
        margin-top: -40px !important;
        margin-left: -70px !important;
        width: 350px !important;
        height: 350px !important;
    }

    .large-item {
        margin-top: -40px !important;
        margin-left: -70px !important;
        width: 370px !important;
        height: 370px !important;
    }


    .circle-btn {
        width: 110px !important;
    }

    .circle-item {
        width: 90px !important;
    }
    .circle-item {
        font-size: calc(4px + 1vw) !important;
    }
}

@media screen and (max-width: 480px) {
    .mobile-margin {
        margin-left: 90px !important;
    }

    .menu-circle {
        height: 330px !important;
        top: 80px !important;
        font-size: 10px !important;
    }

    .menu-item {
        margin-left: -50px !important;
        width: 95px !important;
        height: 95px !important;
    }

    .center-item {
        margin-top: -10px !important;
        margin-left: -72px !important;
        width: 140px !important;
        height: 140px !important;
    }

    .medium-item {
        margin-top: -30px !important;
        margin-left: -90px !important;
        width: 230px !important;
        height: 230px !important;
    }

    .large-item {
        margin-top: -30px !important;
        margin-left: -90px !important;
        width: 250px !important;
        height: 250px !important;
    }

    .circle-btn {
        width: 110px !important;
    }

    .circle-item {
        width: 50px !important;
    }
}

.menu-item,
.center-item {
    margin-left: -80px;
    position: absolute;
    color: #fff;
    text-align: center;
    cursor: pointer;
    z-index: 1000;
}
.menu-item {
    border-radius: 100%;
    width: 240px;
    height: 240px;
}

.center-item {
    margin-top: -20px;
    margin-left: -138px;
    width: 360px;
    height: 360px;
    background-color: #6eb1dc;
    background-image: url(/static/media/heart-1.1b2bcf29.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.center-item:hover {
    background-image: url(/static/media/heart-2.29931bfb.svg);
    /*color: #4472c4;*/
}

.large-item {
    position: absolute;
    border-radius: 100%;
    margin-top: 20px;
    /*margin-left: 20px;*/
    text-align: center;

    width: 600px;
    height: 600px;
    background-color: #b0bcde;
}

.medium-item {
    position: absolute;
    border-radius: 100%;
    margin-top: 20px;
    /*margin-left: 20px;*/
    text-align: center;

    width: 570px;
    height: 570px;
    background-color: #f4f4f4;
}

.menu-circle {
    margin: auto;
    position: relative;
    width: 80px;
    height: 900px;
    top: 350px;
    text-align: center;
    font-size: 13px;
}

.menu-item:hover {
    background: #f9f9f9;
}

.blue {
    background-color: #4472c4;
}

.blue:hover {
    color: #4472c4;
    text-shadow: none;
}

.circle-btn {
    padding-bottom: 20px;
    word-break: break-all;
    width: 150px;
}

.circle-item {
    /*word-break: break-word;*/
    width: 180px;
    font-size: 20px;
}

.color-1 {
    background-color: #ffc13d;
}
.color-2 {
    background-color: #f95e63;
}
.color-3 {
    background-color: #1a61a9;
}
.color-4 {
    background-color: #a7e45f;
}
.color-5 {
    background-color: #00aa5c;
}
.color-6 {
    background-color: #4c5baf;
}
.color-7 {
    background-color: #f588c8;
}
.color-8 {
    background-color: #00bfdc;
}
.color-9 {
    background-color: #E500FF;
}
.color-0 {
    background-color: #f4f4f4;
}

.color-0:hover,
.color-1:hover,
.color-2:hover,
.color-3:hover,
.color-4:hover,
.color-5:hover,
.color-6:hover,
.color-7:hover,
.color-8:hover,
.color-9:hover {
    /*color: #4472c4;*/
    text-shadow: none;
    background-color: #00bFFF;
}

.color-0:hover {
    background-color: unset;
}

.color-0,
.color-1,
.color-2,
.color-3,
.color-4,
.color-5,
.color-6,
.color-7,
.color-8,
.color-9 {
    opacity: 0.9;
}

/**
 * Pricing
 */
@media screen and (max-width: 1440px){
    .extra-pt {
        max-width: 85% !important;
    }
}

@media screen and (max-width: 1200px){
    .btn-card {
        width: 80% !important;
    }
}
@media screen and (max-width: 992px){
    .extra-pt {
        max-width: 95% !important;
    }

    .flex-grid3.reFixing-grid3-basic {
        grid-column-gap: 15px !important;
    }

    .card-basic,
    .card-plus,
    .card-ultimate {;
        padding: 0 5px 100px 5px !important;
    }

    .ml-200 {
        margin-left: 100px !important;
    }

    .mr-200 {
        margin-right: 100px !important;
    }
}

@media screen and (max-width: 768px){
    .simple-header {
        padding: 10px !important;
    }

    .phone-t {
        padding-bottom: 60px !important;
    }
}

@media screen and (max-width: 600px){
    .individual-team {
        padding: 15px 20px !important;
    }

    .extra-pt {
        max-width: 100% !important;
    }

    .btn-card {
        left: 40% !important;
        width: 60% !important;
    }
}

@media screen and (max-width: 480px){
    .justify-rl > div.simple-desc {
        display: inline !important;
        justify-content: center !important;
    }

    .btn-watch-demo {
        display: inline-block !important;
        width: 100% !important;

        margin-top: 20px !important;
    }

    .simple-desc > div.txt-18 {
        margin-top: 20px !important;
    }

    .individual-team {
        margin: 0 auto !important;
        width: 100% !important;
        max-width: 100% !important;
        text-align: center !important;
        justify-content: center !important;
    }

    .justify-individual {
        display: inline !important;
    }
}

.pricing-header-bg {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    height: auto;
    background-color: #57b0d2;
}

.simple-pricing {
    z-index: unset !important;
}

.pricing-header-p {
    padding-top: 30px;
    padding-bottom: 30px;
}

.extra-pt {
    margin-top: 90px;
    max-width: 75%;
}

.btn-watch-demo {
    margin: 0 auto;
    padding: 15px 20px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    width: 200px;
    background-color: var(--col-title-blue);
}

.watch-demo {
    padding-top: 30px;
}

.btn-watch-demo:hover {
    color: var(--col-title-blue);
    background-color: #fff;
}

.btn-watch-demo.contact {
    border: 1px solid var(--col-title-blue);
}

.btn-contact {
    padding: 15px 20px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    width: 200px;
    color: #fff;
    background-color: var(--col-title-blue);
}

.btn-contact:hover {
    color: var(--col-title-blue);
    background-color: #fff;
}

.btn-contact.contact {
    border: 1px solid var(--col-title-blue);
}

.mr-200 {
    margin-right: 200px;
}

.ml-200 {
    margin-left: 200px;
}

.simple-header {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    text-align: center;
}

.simple-desc {
    margin: 0 auto;
    padding: 20px 0;
}

.after-select {
    padding-top: 30px;
}

.individual-team {
    display: flex;
    padding: 15px 40px;
}

.individual-team:hover {
    background-color: var(--col-foreGround);
}

.justify-individual {
    display: flex;
    justify-content: center;
    align-items: center;
}

.basic-plus-pt {
    padding-top: 20px;
}

.reFixing-grid3-basic {
    grid-column-gap: 30px;
}

.card-basic,
.card-plus,
.card-ultimate {
    position: relative;
    margin-bottom: 15px;
    padding: 0 5px 130px 5px;
    border-radius: 4px;
}

.card-basic {
    color: var(--col-heavyDark);
    background-color: var(--col-heavyDark);
}

.card-plus {
    color: var(--col-bttonAndLink);
    background-color: var(--col-bttonAndLink);
}

.card-ultimate {
    color: var(--col-browny);
    background-color: var(--col-browny);
}

.btn-card {
    position: absolute;
    top: 94vh;
    left: 20%;
    padding: 15px 20px;
    border-radius: 4px;
    width: 65%;
    color: var(--col-darkBlue);
    transform: translateX(-50%);
}

.btn-card:hover,
.btn-hot:hover {
    border: 1px solid var(--col-darkBlue);
    background-color: #fff;
    opacity: 0.7;
}

.btn-hot {
    position: absolute;
    top: 10px;
    left: -10px;
    padding: 3px 20px;
    background-color: var(--col-paragraphBg);
}

.pt-40 {
    padding-top: 40px;
}

.txt-month {
    padding-top: 40px;
}

.txt-month-desc {
    padding-top: 10px;
}

.month-pb {
    padding-bottom: 30px;
}

.ptb-50-80 {
    padding-top: 20px;
    padding-bottom: 80px;
}

.ptb--46-70 {
    padding-top: 16px;
    padding-bottom: 70px;
}

.card-icon {
    margin-top: 30px;
    padding: 15px;
    color: var(--col-title-blue);
    background-color: var(--col-foreGround);
}

.card-icon:hover {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25);
}

.txt-hipaa-title {
    padding-top: 20px;
    padding-bottom: 20px;
}

.icon-shadow {
    opacity: 0.8;
}

.can-plan-bg {
    margin-bottom: 20px;
    padding-top: 50px;
    background-color: var(--col-foreGround);
}

.pricing-hr > hr {
    padding: 0 20px 20px;
    margin-bottom: 30px;
    border: none;
    border-bottom: 2px solid var(--col-title);
    width: 100%;
    max-width: 95%;
}

.can-grid2-gaps {
    grid-column-gap: 45px;
}

.can-plan-title {
    padding-bottom: 20px;
}

.pricing-desc-pb {
    padding-bottom: 30px;
}

.phone-email-grid3-gaps {
    margin-top: 50px;
    grid-column-gap: 40px;
}

.info-pb {
    margin-bottom: 65px;
}

.icon-txt-info {
    padding-top: 25px;
    padding-bottom: 15px;
}

.icon-circle-bg {
    position: relative;
    margin: 0 auto;
    width: 72px;
    height: 72px;
    background-image: url(/static/media/ellipse-icon.2cff7765.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.schedule-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/**
 * Shape
 */
.shape-payment1,
.shape-payment2,
.shape-payment3 {
    margin: 0 auto;
    position: relative;
    padding-top: 40px;
    word-break: break-word;
    border: 2px solid var(--col-darkBlue);
    border-radius: 80%;
    width: 30%;
    min-width: 400px;
    height: 93vh;
    line-height: 2.5;
}

.col-shape-payment1 {
    background-color: #4472c4;
}
.col-shape-payment2 {
    background-color: #92d050;
}
.col-shape-payment3 {
    background-color: #7030a0;
}
.border-btn-1 {
    border: 1px solid #4472c4;
}
.border-btn-2 {
    border: 1px solid #92d050;
}
.border-btn-3 {
    border: 1px solid #7030a0;
}
.rectangle-trans {
    position: absolute;
    margin: 77.3vh 85px 0;
    width: 44px;
    height: 80px;
    text-align: center;
    box-shadow: -1px 2px 1px 0 #000;
    transform: skewY(-35deg);
}

.txt-index {
    z-index: 1000;
}
/**
 * View Provider Page
 */
@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 992px) {
    .view-grid3 {
        margin-top: 35px !important;
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
    }

    .view-grid21,
    .view-grid22 {
        grid-template-columns: 1.5fr 1fr !important;
        grid-auto-rows: 1fr !important;
        grid-column-gap: 35px !important;
    }

    .view-grid21 {
        grid-template-rows: auto auto !important;
    }

    .view-grid22 {
        margin-top: 35px !important;
        grid-template-rows: auto auto !important;
    }
}

@media screen and (max-width: 768px) {
    .psychologist-posi {
        left: 0 !important;
        margin: 0 auto !important;
        width: 100% !important;
        text-align: center !important;
    }
}

@media screen and (max-width: 600px) {
    .view-grid21,
    .view-grid22 {
        grid-template-columns: 1fr !important;
        grid-auto-rows: auto !important;
    }

    .view-grid21 {
        margin-bottom: 35px !important;
    }

    .mobile-flex {
        display: flex !important;
    }

    .un-between > div.justify-center {
        display: inline !important;
    }

    .robot-maxWidth {
        margin: 0 10% 15px !important;
        width: 100% !important;
        max-width: 80% !important;
        text-align: center !important;
    }

    .view-ptl {
        padding: 20px !important;
    }

    input {
        max-width: 100% !important;
    }
}

@media screen and (max-width: 480px) {
    .llc-icon-pl {
        padding-left: 0 !important;
    }

    .img-pl {
        padding-left: 0 !important;
    }
    .psychologist-posi {
        padding-top: 120px !important;
    }
    .city-pt {
        display: inline !important;
    }
    .psychologist-posi {
        display: inline !important;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .newYork-pt {
        text-align: center;
        display: inline !important;
    }

    .llc-city-pr {
        padding-top: 20px !important;
        padding-right: 0 !important;
    }

    .llc-cover-bg {
        height: auto !important;
    }
}
/**
 * main
 */
html {
    scroll-behavior: smooth;
}

.view-header-bgColor {
    margin: 0 auto;
    width: 100%;
    height: 92px;
    text-align: center;
}

.llc-cover-bg {
    display: flex;
    position: relative;
    margin: -92px auto 0;
    width: 100%;
    max-width: 100%;
    height: 330px;
    text-align: center;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}

.initial-bg {
    background-position: center top;
    background-image: linear-gradient(0, #0002, #0002), url(/static/media/1.6e66396e.jpg);
}

.profile-photo {
    border-radius: 50%;
}

.img-pl {
    padding-left: 20px;
}

.profile-name {
    color: #fff;
    text-shadow: 0 0 4px #3333;
}

.psychologist-posi {
    display: flex;
    margin-left: 30px;
    padding-bottom: 20px;
    align-items: flex-end;
}

.newYork-pt {
    display: flex;
}

.newYork-pt,
.city-pt {
    text-shadow: 0 0 4px #3333;
}

.city-pt {
    display: flex;
    padding-bottom: 30px;
}

.view-header-pt {
    padding-top: 46px;
}

.view-grid3 {
    display: grid;
    /*padding-bottom: 36px;*/
    grid-template-columns: 2.6fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 15px;
    word-break: break-word;
}


.contact-grid2 {
}

.view-grid21 {
    grid-template-rows: 6fr 1fr;
}

.view-grid22 {
    grid-template-rows: 1fr 1fr;
}

.view-grid21,
.view-grid22 {
    /*display: grid;*/
    /*grid-auto-columns: auto;*/
    /*grid-row-gap: 36px;*/
}

.bg-grey {
    background-color: #f7f8f8;
}

.bg-blue {
    background-color: #24466f;
}

.bg-area {
    background-color: #56b0d2;
}

.bg-gray {
    background-color: #869791;
}

.bg-service {
    background-color: #abf2d3;
}

.col-white {
    color: #fff;
}

.view-grid-bg > div > span {
    font-size: 10px;
    color: var(--col-paragraphBg);
}


.view-ptl {
    margin-bottom: 15px;
    padding: 20px 25px 30px;
    min-height: 150px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.about-height {
    min-height: 430px;
}

.login-for {
    margin: 36px auto 0;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 128px;
    text-align: center;
    background-color: #57b0d2;
}

.btn-view-login {
    padding: 15px 80px;
}

hr {
    padding: 5px 0;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    text-align: center;

    background-color: var(--col-part-bg);
}

.view-icon1-pt {
    padding-top: 10px;
}

.view-icon-desc {
    padding-left: 5px;
}

.view-desc-pt {
    padding-top: 15px;
}

.map-img {
    margin-bottom: 20px;
    min-width: 200px;
}

.input-pb {
    margin-bottom: 25px;
}

.input-message {
    padding-top: 20px;
    padding-bottom: 50px;
    max-width: 100%;
    height: auto;
}

.asteristic-pl {
    padding-left: 10px;
}

.llc-icon-pl {
    padding-left: 10px;
}

.llc-city-pr {
    padding-right: 50px;
}

.input-maxW {
    max-width: 40%;
}

input {
    padding: 10px;
    margin-bottom: 17px;
    border: 1px solid var(--col-lightColor);
    border-radius: 4px;

    width: 100%;
    max-width: 100%;
    background-color: #ffffff;
}

::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 22px;
    color: var(--col-lightColor);
}

:-ms-input-placeholder {
    font-size: 16px;
    line-height: 22px;
    color: var(--col-lightColor);
}

::-ms-input-placeholder {
    font-size: 16px;
    line-height: 22px;
    color: var(--col-lightColor);
}

::placeholder {
    font-size: 16px;
    line-height: 22px;
    color: var(--col-lightColor);
}

a {
    color: #ffffff;
}

.address-container {
    overflow: hidden;
}

.map-container {
    margin: -22px -27px 0;
}

.provider-thumbnail {
    background-color: #fff;
}

.provider-thumbnail:hover {
    transform: scale(1.05);
    box-shadow: 0 0 16px 2px #add;
    z-index: 1000;
}

