
/**
 * Footer page
 */

@media screen and (max-width: 480px){
    .btn-contact-us,
    .social-icon6 {
        width: 100% !important;
        max-width: 100% !important;
        text-align: center !important;
    }
}
.footer-pd {
    padding: 100px 0 80px;
}

.footer-bg {
    background-color: #fff;
}

.txt-align {
    text-align: left;
    line-height: 3;
}

.btn-contact-us {
    margin-top: 20px;
    margin-bottom: 32px;
    padding: 0 35px;
    border: 1px solid var(--col-title-blue);
    border-radius: 4px;
}

.btn-contact-us:hover {
    color: #ffffff;
    background-color: var(--col-title-blue);
}

.contact-align {
    display: flex;
    vertical-align: middle;
    align-items: center;
    text-align: left;
}

.icon-mr {
    margin-right: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.stripe-pt {
    padding-top: 85px;
}

.hipaa-stripe {
    padding-right: 29px;
}

.info-fixed {
    position: fixed;
    z-index: 3000;
    bottom: 30px;
    left: 50px;
    color: black;
}